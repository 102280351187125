/* stylelint-disable */
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_elapsedTime {
        text-align: right;
        padding-bottom: 24px;
    }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_elapsedTime .sysmexeuropeMoleculeScientificCalendar_elapsedTime__title {
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 10px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_elapsedTime .sysmexeuropeMoleculeScientificCalendar_elapsedTime__time {
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper {
        padding-top: 32px;
        padding-bottom: 32px;
        background-color: #FFFFFF;
    }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_questionsInnerWrapper {
            padding-right: 32px;
            padding-left: 32px
        }
@media only screen and (min-width: 992px) {

        .sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_questionsInnerWrapper {
                width: 50%;
                margin: 0 auto
        }
            }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_questions {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */
        }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer {
                display: flex;
                align-items: center;

                font-style: normal;

                font-weight: 400;

                line-height: 26px;

                margin-bottom: 0;

                color: #333333;

                text-transform: none;

                font-size: 18px;

                /* font-family: "Source Sans Pro", sans-serif; */

                border: 1px solid rgba(0,0,0,0.1);
                padding: 24px;
                transition: all 0.2s
            }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer.sysmexeuropeMoleculeScientificCalendar_is--selected,
                .sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer:hover {
                    background-color: rgba(0,91,172,0.1);
                    color: #005BAC;
                }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer:hover {
                    cursor: pointer;
                }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer.sysmexeuropeMoleculeScientificCalendar_is--correct {
                    background-color: #0DAC67;
                    color: #FFFFFF;
                }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer.sysmexeuropeMoleculeScientificCalendar_is--false {
                    background-color: #E7413E;
                    color: #FFFFFF;
                }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer p {
                    margin-bottom: 0;
                }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer .sysmexeuropeMoleculeScientificCalendar_radio,
                .sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer .sysmexeuropeMoleculeScientificCalendar_checkbox {
                    margin-bottom: 0;
                    pointer-events: none;
                }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer .sysmexeuropeMoleculeScientificCalendar_radio input, .sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_answers .sysmexeuropeMoleculeScientificCalendar_answer .sysmexeuropeMoleculeScientificCalendar_checkbox input {
                        pointer-events: none;
                    }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_submit {
            margin-top: 24px
        }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_submit.sysmexeuropeMoleculeScientificCalendar_is--completed {
                display: none;
            }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_reset {
            display: none
        }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_reset.sysmexeuropeMoleculeScientificCalendar_is--completed {
                display: block;
            }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_feedback {
            text-align: center;
            display: none;

            font-style: normal;

            font-weight: 600;

            line-height: 32px;

            margin-bottom: 24px;

            color: #333333;

            text-transform: none;

            font-size: 27px

            /* font-family: "Source Sans Pro", sans-serif; */
        }
.sysmexeuropeMoleculeScientificCalendar_scientificCalendar .sysmexeuropeMoleculeScientificCalendar_questionsWrapper .sysmexeuropeMoleculeScientificCalendar_feedback.sysmexeuropeMoleculeScientificCalendar_is--completed {
                display: block;
            }

/* stylelint-disable */
.sysmexeuropeOrganismAmrJourney_amr-journey {
    display: grid;
    grid-template-columns: [full-width-start] 24px [content-start] 1fr [content-end] 24px [full-width-end];
    background-color: #AAD2DC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden
}
@media only screen and (min-width: 992px) {
.sysmexeuropeOrganismAmrJourney_amr-journey {
        grid-template-columns: [full-width-start] minmax(16px, 140px) [content-start] 1fr [content-end] minmax(16px, 140px) [full-width-end]
}
    }
.sysmexeuropeOrganismAmrJourney_amr-journey header,
    .sysmexeuropeOrganismAmrJourney_amr-journey section {
        position: relative;
        margin: 0 0 4rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_grid {
        display: grid;
        align-items: center;
        justify-items: center;
        place-items: center;
        grid-column: 2;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_amr-link-arrow {
        height: 16px;
        margin-bottom: -2px;
    }
/* Typography */
.sysmexeuropeOrganismAmrJourney_amr-journey h1 { /* https://webaim.org/techniques/css/invisiblecontent/ - Hidden h1 added because visible top headline is masked and split in two parts */
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey span.sysmexeuropeOrganismAmrJourney_top-headline,
    .sysmexeuropeOrganismAmrJourney_amr-journey h2 {
        color: #AAD2DC;
        display: inline-block;
        font-weight: 400;
        line-height: 1.34;
        border-bottom: none;
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0;
        text-align: center;
        white-space: nowrap
    }
@media only screen and (min-width: 768px) {

    .sysmexeuropeOrganismAmrJourney_amr-journey span.sysmexeuropeOrganismAmrJourney_top-headline,
    .sysmexeuropeOrganismAmrJourney_amr-journey h2 {
            max-width: 70vw
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey span.sysmexeuropeOrganismAmrJourney_top-headline span.sysmexeuropeOrganismAmrJourney_white-background, .sysmexeuropeOrganismAmrJourney_amr-journey h2 span.sysmexeuropeOrganismAmrJourney_white-background {
            padding: 0 0.6rem;
            background-color: #FFFFFF;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey h2 {
        margin-bottom: 2rem
    }
@media only screen and (min-width: 992px) {

    .sysmexeuropeOrganismAmrJourney_amr-journey h2 {
            margin-bottom: 5rem
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey h3 {
        color: #FFFFFF;
        text-transform: uppercase;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey p,
    .sysmexeuropeOrganismAmrJourney_amr-journey a {
        font-size: 1.2rem;
        font-weight: 400;
        color: #005BAC;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-text {
        padding-right: 4px;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_break-word {
        word-break: break-all;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-large {
        font-size: clamp(1.8rem, calc(4vw), 5rem);
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_numbers {
        font-size: 4rem
    }
@media only screen and (min-width: 768px) {

    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_numbers {
            font-size: clamp(2rem, calc(4vw), 5rem)
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-medium {
        font-size: clamp(2rem, calc(3.5vw), 4rem);
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-small {
        font-size: clamp(2rem, calc(2vw), 3rem)
    }
@media only screen and (min-width: 768px) {

    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-small {
            max-width: 40vw
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_main-headline-wrapper {
        display: grid;
        align-content: center;
        justify-content: center;
        place-content: center;
        z-index: 1;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_mask-wrapper-a,
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_mask-wrapper-b,
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_mask-wrapper-c,
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_mask-wrapper-d {
        overflow: hidden;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-mask-a,
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-mask-b,
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-mask-c,
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_text-mask-d {
        overflow: hidden;
        text-align: center;
    }
/* Misc */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_border {
        width: 100%;
        height: 1px;
        background-color: #005BAC;
        margin: 0 0 1.5rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_teaserCard img {
            aspect-ratio: 1.77777778;
        }
/* Icons */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_icon-link {
        z-index: 9999;
        position: absolute;
        right: 24px;
        bottom: 24px;
    }
/* Content container & text wrapper */
.sysmexeuropeOrganismAmrJourney_amr-journey [dir="ltr"] .sysmexeuropeOrganismAmrJourney_content-container {
        text-align: left
}
.sysmexeuropeOrganismAmrJourney_amr-journey [dir="rtl"] .sysmexeuropeOrganismAmrJourney_content-container {
        text-align: right
}
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0 1rem;
        gap: 0 1rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-wrapper {
            margin-bottom: 1rem;
            width: 400px;
            max-width: 80vw;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-wrapper,
        .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-spacer {
            flex: 1;
            min-width: 300px;
        }
@media only screen and (min-width: 768px) {
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container {
            grid-gap: 0 2rem;
            gap: 0 2rem;
    }

            .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-wrapper,
            .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-spacer {
                flex: 1;
                min-width: unset;
            }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-wrapper-inner {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_content-wrapper-inner p {
            line-height: 0.72;
            margin: 0 0 0.4rem;
            padding: 0;
        }
/* Sticker and icons */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_amr-fighter-sticker {
        width: 100%;
        max-width: 240px;
        height: auto;
        position: absolute;
        left: 4vw;
        top: 15rem;
        display: none
    }
@media only screen and (min-width: 768px) {
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_amr-fighter-sticker {
            max-width: 300px;
            left: 4vw;
            display: block
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_amr-fighter-icon {
        width: auto;
        height: 100%;
        padding-right: 1rem;
    }
/* Header */
.sysmexeuropeOrganismAmrJourney_amr-journey header {
        position: relative;
        height: 40vh;
        display: grid;
        margin-bottom: 0
    }
@media only screen and (min-width: 768px) {
    .sysmexeuropeOrganismAmrJourney_amr-journey header {
            min-height: 480px
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey header .sysmexeuropeOrganismAmrJourney_amr-fighter-sticker {
            top: 11rem;
        }
/* AST image section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_ast-image-section {
        grid-column: full-width-start / full-width-end;
        height: 600px;
        overflow: hidden;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_ast-image-section .sysmexeuropeOrganismAmrJourney_ast-headline {
            position: relative;
            margin-bottom: 0;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_ast-image-section .sysmexeuropeOrganismAmrJourney_ast-image {
            width: auto;
            height: 1000px;
            position: absolute;
            top: -500px;
            right: 0;
        }
/* Facts & AST section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_facts-section {
        margin-bottom: 1rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_ast-section {
        min-height: 500px;
        align-content: start;
        justify-content: start;
        place-content: start;
        margin-bottom: 3rem
    }
@media only screen and (min-width: 768px) {

    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_ast-section {
            margin-bottom: 5rem
    }
        }
/* Marquee section with image */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section {
        display: grid;
        grid-column: full-width-start / full-width-end;
        align-items: center;
        justify-items: center;
        place-items: center;
        margin-bottom: 3rem
    }
@media only screen and (min-width: 768px) {
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section {
            min-height: 840px;
            margin-bottom: 0
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_un-series-headline {
            position: relative;
            justify-self: center;
            align-self: center
        }
@media only screen and (min-width: 768px) {

        .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_un-series-headline {
                position: absolute;
                justify-self: start;
                left: 16vw;
                top: 1rem
        }
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_un-series-image {
            width: 1000px;
            max-width: 90vw;
            height: auto;
            position: relative
        }
@media only screen and (min-width: 768px) {

        .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_un-series-image {
                position: absolute;
                z-index: 1
        }
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_marquee-outer-wrapper {
            overflow: hidden;
            white-space: nowrap;
            width: 102%;
            margin-left: -1%;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_marquee-outer-wrapper--pink {
                display: none;
            }
@media only screen and (min-width: 768px) {
                .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_marquee-outer-wrapper--pink {
                    display: block;
                }
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_marquee-outer-wrapper .sysmexeuropeOrganismAmrJourney_marquee-inner-wrapper {
                font-size: 0;
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_marquee-outer-wrapper .sysmexeuropeOrganismAmrJourney_marquee-inner-wrapper .sysmexeuropeOrganismAmrJourney_marquee-text {
                    display: inline-block
                }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section .sysmexeuropeOrganismAmrJourney_marquee-outer-wrapper .sysmexeuropeOrganismAmrJourney_marquee-inner-wrapper .sysmexeuropeOrganismAmrJourney_marquee-text:nth-of-type(2) {
                        margin-left: -0.2rem;
                    }
/* UTI image section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_uti-image-section {
        grid-column: full-width-start / full-width-end;
        height: 600px;
        overflow: hidden;
        margin-bottom: 6rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_uti-image-section .sysmexeuropeOrganismAmrJourney_uti-headline {
            position: relative;
            margin-bottom: 0;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_uti-image-section .sysmexeuropeOrganismAmrJourney_bacteria-image {
            width: auto;
            height: 1000px;
            position: absolute;
            top: -500px;
            right: 0;
        }
/* Link section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section {
        margin-bottom: 1rem;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section .sysmexeuropeOrganismAmrJourney_headline-medium {
            max-width: unset;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section .sysmexeuropeOrganismAmrJourney_content-container {
            display: inline-block;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-wrapper {
                display: flex;
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section .sysmexeuropeOrganismAmrJourney_content-container .sysmexeuropeOrganismAmrJourney_content-wrapper .sysmexeuropeOrganismAmrJourney_teaserCard {
                    flex-grow: 1;
                }
@media only screen and (min-width: 768px) {
            .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section .sysmexeuropeOrganismAmrJourney_content-container {
                display: inline-flex;
            }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section a div {
                flex: 1;
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_link-section a div img {
                    height: 100%;
                }
/* Marquee only section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section--marquee-only {
        min-height: 200px;
        margin-bottom: 1rem
    }
@media only screen and (min-width: 768px) {
    .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section--marquee-only {
            min-height: 300px;
            margin-bottom: 0
    }
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section--marquee-only .sysmexeuropeOrganismAmrJourney_amr-fighter-sticker-turquoise {
            margin-bottom: -4rem;
            display: block
        }
@media only screen and (min-width: 768px) {

        .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_marquee-section--marquee-only .sysmexeuropeOrganismAmrJourney_amr-fighter-sticker-turquoise {
                display: none
        }
            }
/* Slider section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section {
        grid-column: full-width-start / full-width-end;
        overflow: hidden;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section-header {
            margin-bottom: 0;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_header-wrapper {
            position: relative;
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_header-wrapper .sysmexeuropeOrganismAmrJourney_main-headline-wrapper h2 {
                    margin-bottom: 0;
                }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_wrapper {
            position: relative;
            display: flex;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_box {
            box-sizing: border-box;
            display: flex;
            align-items: stretch;
            width: calc(100vw - 1rem);
            min-width: 100vw;
            margin: 0;
            position: relative;
            cursor: pointer
        }
@media only screen and (min-width: 768px) {

        .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_box {
                width: 400px;
                min-width: unset
        }
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_box a {
                display: flex;
                flex-direction: column;
                padding: 0 1rem
            }
@media only screen and (min-width: 768px) {

            .sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_box a {
                    padding: 0 0 0 1rem
            }
                }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_prev {
            position: absolute;
            top: 80px;
            left: 0;
            z-index: 9999;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section .sysmexeuropeOrganismAmrJourney_next {
            position: absolute;
            top: 80px;
            right: 0;
            z-index: 9999;
        }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_slider-section button {
            padding: 0.6rem;
            border: none;
            color: #FFFFFF;
            background: #005BAC;
            cursor: pointer;
        }
/* Sources section */
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_sources-section {
        grid-column: content-start / content-end;
        margin-bottom: 6rem;
        word-break: break-all;
    }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_sources-section .sysmexeuropeOrganismAmrJourney_sources-container h2 {
                color: #FFFFFF;
                text-transform: uppercase;
                margin-bottom: 2rem;
                font-size: 22px;
                font-weight: 600;
            }
.sysmexeuropeOrganismAmrJourney_amr-journey .sysmexeuropeOrganismAmrJourney_sources-section .sysmexeuropeOrganismAmrJourney_sources-container a {
                display: block;
                margin-bottom: 1.5rem;
            }

/* stylelint-disable */
.sysmexeuropeOrganismCareerDetail_career-detail-page {
    display: flex;
    flex-direction: column
}
@media only screen and (min-width: 992px) {
.sysmexeuropeOrganismCareerDetail_career-detail-page {
        flex-direction: row
}
    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_mobileOnly {
        display: block
    }
@media only screen and (min-width: 992px) {

    .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_mobileOnly {
            display: none
    }
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_desktopOnly {
        display: none
    }
@media only screen and (min-width: 992px) {

    .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_desktopOnly {
            display: block
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main {
            padding-right: 96px;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between
    }
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_intro {
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 16px;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos > div {
                margin-bottom: 40px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos h3,
            .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos .sysmexeuropeOrganismCareerDetail_header {
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: uppercase;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                padding-bottom: 24px
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos h3 + div ul, .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos .sysmexeuropeOrganismCareerDetail_header + div ul {
                        margin-top: 0;
                    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos .sysmexeuropeOrganismCareerDetail_content {
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                margin-bottom: 24px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos .sysmexeuropeOrganismCareerDetail_content-link a {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    text-align: right;
                    display: inherit;
                }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfos .sysmexeuropeOrganismCareerDetail_jobInfos-description {
                background-color: #FFFFFF;
                padding: 24px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_jobInfosFullWidth {
            max-width: 100%;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_quote {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            justify-content: space-between;
            margin: 48px 0 40px;
            background-color: #FFFFFF
        }
@media only screen and (min-width: 992px) {

        .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_quote {
                flex-direction: row
        }
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_quote .sysmexeuropeOrganismCareerDetail_quote-image img {
                    width: 100%;
                    -o-object-fit: cover;
                       object-fit: cover
                }
@media only screen and (min-width: 992px) {
                .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_quote .sysmexeuropeOrganismCareerDetail_quote-image img {
                        width: auto;
                        padding-right: 24px
                }
                    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_quote .sysmexeuropeOrganismCareerDetail_quote-text {
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                padding: 16px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_quote .sysmexeuropeOrganismCareerDetail_quote-text .sysmexeuropeOrganismCareerDetail_quote-author {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 14px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    font-weight: bold;
                    margin-top: 24px;
                }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_desktop-social {
            margin-bottom: 24px;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_desktop-social ul li {
                    height: 6em;
                }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-main .sysmexeuropeOrganismCareerDetail_desktop-social ul li i {
                        font-size: 6em;
                    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar {
        margin-bottom: 16px;
    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_title {
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 23px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-weight: 600;
            margin-bottom: 16px;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_intro {
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 23px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 32px;
        }
@media only screen and (min-width: 992px) {

    .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar {
            width: 352px;
            flex: 0 0 auto;
            margin-bottom: 0
    }
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar > div {
            padding: 24px 32px;
            background-color: #FFFFFF
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar > div:nth-child(2n+3) {
                background-color: rgba(0,91,172,0.1);
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_sidebar-header {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            border-bottom: 1px solid rgba(51, 51, 51, 0.19);
            border-top: 1px solid rgba(51, 51, 51, 0.19);
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: -32px;
            margin-top: -24px;
            margin-right: -32px;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_sidebar-header .sysmexeuropeOrganismCareerDetail_sidebar-header-content {
                padding: 8px 32px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_header {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 8px;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_content {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px
            /* font-family: "Source Sans Pro", sans-serif; */
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_content::first-letter {
                text-transform: capitalize;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-image {
                display: flex;
                justify-content: center;
                margin: 24px 0 32px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-image img {
                    border-radius: 50%
                }
@media only screen and (min-width: 992px) {

                .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-image img {
                        width: 160px
                }
                    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-adress {
                margin-bottom: 24px;
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-adress .sysmexeuropeOrganismCareerDetail_job-contact-adress-name {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    margin-bottom: 16px;
                }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-adress .sysmexeuropeOrganismCareerDetail_job-contact-adress-phone,
                .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-adress .sysmexeuropeOrganismCareerDetail_job-contact-adress-email {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    display: flex;
                    align-items: center;
                }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-adress .sysmexeuropeOrganismCareerDetail_job-contact-adress-phone i, .sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_contact-person .sysmexeuropeOrganismCareerDetail_job-contact-adress .sysmexeuropeOrganismCareerDetail_job-contact-adress-email i {
                        font-size: 32px;
                    }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_maillink button {
                margin-top: 8px;
                color: #000000;
                border: 2px solid #000000
            }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_maillink button:hover {
                    color: #005BAC;
                    border: 2px solid #005BAC;
                }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_content-sitebar .sysmexeuropeOrganismCareerDetail_sidebar-map {
            padding: 32px 0;
        }
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_link_back {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
    }
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexeuropeOrganismCareerDetail_career-detail-page .sysmexeuropeOrganismCareerDetail_link_back a {
            display: flex;
            align-items: center;
        }

/* stylelint-disable */
.sysmexeuropeOrganismLibraryDetail_detail-page h3 {
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 27px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: 32px
    }
@media only screen and (min-width: 992px) {

    .sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main {
            flex-direction: row
    }
        }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentLeft {
            background-color: #FFFFFF;
            height: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 auto;
        }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentLeft .sysmexeuropeOrganismLibraryDetail_image {
                margin: 16px 0;
            }
@media only screen and (min-width: 992px) {

        .sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentLeft {
                width: 352px
        }
            }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight {
            background-color: #FFFFFF;
            padding: 24px 16px;
            flex-grow: 1;
        }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content {
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_tableContent {
                    width: 100%;
                    margin: 24px 0;
                }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_tableContent .sysmexeuropeOrganismLibraryDetail_tableContentRow {
                        display: inline-block;
                    }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_tableContent .sysmexeuropeOrganismLibraryDetail_tableContentRow > div {
                            display: inline-block;
                            padding-right: 24px
                        }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_tableContent .sysmexeuropeOrganismLibraryDetail_tableContentRow > div:nth-child(2n+1) {
                                padding-right: 8px;

                                font-style: normal;

                                font-weight: 600;

                                line-height: 24px;

                                margin-bottom: 0;

                                color: #333333;

                                text-transform: none;

                                font-size: 18px;

                                /* font-family: "Source Sans Pro", sans-serif; */
                            }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_links {
                    display: flex;
                    justify-content: flex-end;
                }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_links .sysmexeuropeOrganismLibraryDetail_download {
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 0;
                        color: #333333;
                        text-transform: none;
                        font-size: 18px;
                        /* font-family: "Source Sans Pro", sans-serif; */

                        display: inline-block;
                        padding: 8px 0;
                        text-decoration: none
                    }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_content-main .sysmexeuropeOrganismLibraryDetail_contentRight .sysmexeuropeOrganismLibraryDetail_content .sysmexeuropeOrganismLibraryDetail_links .sysmexeuropeOrganismLibraryDetail_download:hover {
                            color: #00B8EE;
                        }
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_link_back {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
    }
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexeuropeOrganismLibraryDetail_detail-page .sysmexeuropeOrganismLibraryDetail_link_back a {
            display: flex;
            align-items: center;
        }

.sysmexeuropeOrganismThumbnailGallery_thumbnail-gallery .sysmexeuropeOrganismThumbnailGallery_image {
        margin-bottom: 16px;
    }

        .sysmexeuropeOrganismThumbnailGallery_thumbnail-gallery .sysmexeuropeOrganismThumbnailGallery_image img {
            width: 100%;
            height: auto;
            -o-object-fit: cover;
               object-fit: cover;
            min-height: 150px;
        }

